import { REMOVE_USER, RECEIVE_USER } from './actions'

const initialState = false

const userReducer = function userReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_USER:
      return false
    case RECEIVE_USER:
      return Object.assign({}, action, action.payload)
    default:
      return state
  }
}

export default userReducer
