import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import reducer from './reducer'
import IndexSagas from './sagas'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const middleware = [thunk, sagaMiddleware]

const composedEnhancers =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : compose(applyMiddleware(...middleware))

const store = createStore(reducer(history), initialState, composedEnhancers)

sagaMiddleware.run(IndexSagas)

export default store
