import styled from 'styled-components'
import { Section, palette } from 'style'

export const StyledDiv = styled(Section)`
  flex-direction: row;
`

export const StyledSection = styled(Section)`
  flex-direction: column;
`

export const StyledButton = styled.div`
  width: 25vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ focussed }) => (focussed ? palette.white : palette.black)};
  background: ${({ focussed }) => (focussed ? palette.orange : palette.white)};
  margin-top: 8vh;
  border-radius: 1vw;
  font-size: 2.5vw;
  font-family: 'Gotham Book', sans-serif;
`

export const StyledAmazonButton = styled.div`
  img {
    width: 20vw;
    margin: 2vw;
  }
  border-radius: 2vw;
  border: 4px solid
    ${({ focussed }) => (focussed ? palette.orange : 'transparent')};
`
export const FallbackDiv = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #6caef7;
`
