import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchContent } from 'App/content/actions'
import { fetchFreeVideos, fetchCarouselVideos } from 'App/videos/actions'
import { usedKey } from 'App/focus/actions'
import { loginFromTokenRequest } from 'App/auth/actions'
import { history } from 'store'
import { destroyPlayer } from 'components/Player/actions'
import AppView from './AppView'

class App extends Component {
  state = {
    loadApp: false
  }

  UNSAFE_componentWillMount() {
    const { loginFromTokenRequest } = this.props
    loginFromTokenRequest()
  }

  componentDidMount() {
    const { REACT_APP_BUILD_TYPE } = process.env
    if (REACT_APP_BUILD_TYPE === 'virgin') {
      try {
        window.vmDtvLib.lib.init(err => {
          if (!!err) {
            // TODO: we need to do something here?
          } else {
            try {
              window.vmDtvLib.customer.isPackageEntitled(2038, result => {
                this.setState({ loadApp: true })
                return result
                  ? history.push('/home')
                  : history.push('/entitlement')
              })
            } catch (e) {
              console.log(e)
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      this.setState({ loadApp: true })
    }
    const { fetchContent, fetchFreeVideos, fetchCarouselVideos } = this.props
    fetchContent()
    fetchFreeVideos()
    fetchCarouselVideos()
    document.addEventListener('backbutton', this.onBackKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('backbutton', this.onBackKeyDown)
  }

  onAndroidBackPress = () => {}

  onBackKeyDown = e => {
    e.preventDefault()
    const { usedKey } = this.props
    usedKey('back')
  }

  render() {
    const {
      content: { contentReady },
      videos: { freeVideosReady, carouselsReady }
    } = this.props
    const { loadApp } = this.state
    return (
      contentReady &&
      freeVideosReady &&
      carouselsReady &&
      loadApp && <AppView />
    )
  }
}

const mapStateToProps = ({ content, videos, user }) => ({
  content,
  videos,
  user
})

const connected = connect(
  mapStateToProps,
  {
    fetchContent,
    fetchFreeVideos,
    fetchCarouselVideos,
    loginFromTokenRequest,
    destroyPlayer,
    usedKey
  }
)(App)

export default connected
