import enGB from 'I18n/en-GB.json'
import is from 'I18n/is.json'
import fr from 'I18n/fr.json'

export const getContent = () => {
  const { REACT_APP_BUILD } = process.env
  switch (REACT_APP_BUILD) {
    case 'en-gb':
      return enGB
    case 'is':
      return is
    case 'fr':
      return fr
    default:
      return enGB
  }
}
