export const MOVE_UP = 'MOVE_UP'
export const MOVE_DOWN = 'MOVE_DOWN'
export const MOVE_RIGHT = 'MOVE_RIGHT'
export const MOVE_LEFT = 'MOVE_LEFT'
export const PRESS_BACK = 'PRESS_BACK'
export const PRESS_ENTER = 'PRESS_ENTER'
export const MOVE_X_FOCUS = 'MOVE_X_FOCUS'
export const MOVE_Y_FOCUS = 'MOVE_Y_FOCUS'
export const RESET_X_FOCUS = 'RESET_X_FOCUS'
export const RESET_Y_FOCUS = 'RESET_Y_FOCUS'
export const SCROLL_HEIGHT = 'SCROLL_HEIGHT'
export const IMAGE_WIDTH = 'IMAGE_WIDTH'
export const PLAY_PAUSE = 'PLAY_PAUSE'
export const FAST_FORWARD = 'FAST_FORWARD'
export const REWIND = 'REWIND'

export const usedKey = type => {
  switch (type) {
    case 'up':
      return dispatch => dispatch(moveUp())
    case 'down':
      return dispatch => dispatch(moveDown())
    case 'left':
      return dispatch => dispatch(moveLeft())
    case 'right':
      return dispatch => dispatch(moveRight())
    case 'back':
      return dispatch => dispatch(pressBack())
    case 'enter':
      return dispatch => dispatch(pressEnter())
    case 'playPause':
      return dispatch => dispatch(playPause())
    case 'fastForward':
      return dispatch => dispatch(fastForward())
    case 'rewind':
      return dispatch => dispatch(rewind())
    default:
      return
  }
}

export const moveXFocus = shift => dispatch => dispatch(xFocus(shift))
export const moveYFocus = shift => dispatch => dispatch(yFocus(shift))
export const resetXFocus = reset => dispatch => dispatch(xFocusReset(reset))
export const resetYFocus = reset => dispatch => dispatch(yFocusReset(reset))
export const measureScrollHeight = height => dispatch =>
  dispatch(measureScroll(height))
export const measureImageWidth = width => dispatch =>
  dispatch(measureImage(width))

const moveUp = () => ({ type: MOVE_UP })
const moveDown = () => ({ type: MOVE_DOWN })
const moveRight = () => ({ type: MOVE_RIGHT })
const moveLeft = () => ({ type: MOVE_LEFT })
const pressEnter = () => ({ type: PRESS_ENTER })
const pressBack = () => ({ type: PRESS_BACK })
const fastForward = () => ({ type: FAST_FORWARD })
const rewind = () => ({ type: REWIND })
const playPause = () => ({ type: PLAY_PAUSE })

const measureScroll = height => ({
  type: SCROLL_HEIGHT,
  payload: height
})

const measureImage = width => ({
  type: IMAGE_WIDTH,
  payload: width
})

const xFocus = shift => ({
  type: MOVE_X_FOCUS,
  payload: shift
})

const yFocus = shift => ({
  type: MOVE_Y_FOCUS,
  payload: shift
})

const xFocusReset = reset => ({
  type: RESET_X_FOCUS,
  payload: reset
})

const yFocusReset = reset => ({
  type: RESET_Y_FOCUS,
  payload: reset
})
