import React, { Component } from 'react'
import { connect } from 'react-redux'
import keydown from 'react-keydown'
import { Route } from 'react-router-dom'
import { usedKey } from 'App/focus/actions'
import Onboarding from 'components/Onboarding'
import { StyledSection } from './Page.style'

class Page extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { usedKey } = this.props
    const {
      keydown: { event }
    } = nextProps
    event && event.which === 8 && usedKey('back') // back button
    event && event.which === 27 && usedKey('back') // backbutton (virgin)
    event && event.which === 461 && usedKey('back') // backbutton (gibtelcom)
    event && event.which === 13 && usedKey('enter') // enter
    event && event.which === 37 && usedKey('left') // left
    event && event.which === 38 && usedKey('up') // up
    event && event.which === 39 && usedKey('right') // right
    event && event.which === 40 && usedKey('down') // down
  }

  render() {
    return (
      <StyledSection>
        <Route exact path={'/'} render={props => <Onboarding {...props} />} />
      </StyledSection>
    )
  }
}

const connected = connect(
  null,
  { usedKey }
)(Page)
export default keydown(connected)
