import { REQUEST_CONTENT, RECEIVE_CONTENT } from './actions'

const initialState = {
  content: {},
  contentReady: false
}

const contentReducer = function contentReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CONTENT:
      return {
        content: {},
        contentReady: false
      }
    case RECEIVE_CONTENT:
      return {
        content: action.payload,
        contentReady: true
      }
    default:
      return state
  }
}

export default contentReducer
