import { SHOW_MODAL, HIDE_MODAL } from './actions'

const initialState = false

const reducer = function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return action.payload
    case HIDE_MODAL:
      return false
    default:
      return state
  }
}

export default reducer
