import { baseRequest } from 'utils'

export const REQUEST_FREE_VIDEOS = 'REQUEST_FREE_VIDEOS'
export const RECEIVE_FREE_VIDEOS = 'RECEIVE_FREE_VIDEOS'
export const REQUEST_CAROUSEL_VIDEOS = 'REQUEST_CAROUSEL_VIDEOS'
export const RECEIVE_CAROUSEL_VIDEOS = 'RECEIVE_CAROUSEL_VIDEOS'
export const ERROR_VIDEOS = 'ERROR_VIDEOS'
export const ADD_TO_LAST_WATCHED = 'ADD_TO_LAST_WATCHED'
export const CLEAR_LAST_WATCHED = 'CLEAR_LAST_WATCHED'

export const fetchFreeVideos = dispatch => dispatch => {
  dispatch(requestFreeVideos())
  baseRequest
    .get('videos/schedule/free?deviceType=tv&resolution=3')
    .then(response => {
      const videos = []
      response.data.items.map(item => videos.push(item['free_video']))
      dispatch(receiveFreeVideos(videos))
    })
    .catch(err => error(err))
}
const requestFreeVideos = () => ({ type: REQUEST_FREE_VIDEOS })
const receiveFreeVideos = payload => ({ type: RECEIVE_FREE_VIDEOS, payload })

export const fetchCarouselVideos = dispatch => dispatch => {
  dispatch(requestCarouselVideos())
  let apiUrl = 'content/curriculum_areas?resolution=3'
  const { REACT_APP_BUILD_TYPE } = process.env
  if (REACT_APP_BUILD_TYPE === 'vodafonero') {
    // TODO - and include docs
    apiUrl = `${apiUrl}&country=RO&device_lang=RO`
  }
  baseRequest
    .get(apiUrl)
    .then(response => {
      const { curriculum_areas } = response.data
      const ca = []
      curriculum_areas.map(c => c.collections.map(coll => ca.push(coll)))
      const shows = ca.filter(
        (show, i, self) => self.findIndex(item => item.hid === show.hid) === i
      )
      dispatch(
        receiveCarouselVideos({
          carousels: curriculum_areas,
          shows
        })
      )
    })
    .catch(err => error(err))
}
const requestCarouselVideos = () => ({ type: REQUEST_CAROUSEL_VIDEOS })
const receiveCarouselVideos = payload => ({
  type: RECEIVE_CAROUSEL_VIDEOS,
  payload
})

const error = payload => ({ type: ERROR_VIDEOS, payload })

// export function addToLastWatched(payload) {
//   return {
//     type: ADD_TO_LAST_WATCHED,
//     payload
//   }
// }
//
export function clearLastWatched(payload) {
  return {
    type: CLEAR_LAST_WATCHED,
    payload
  }
}
