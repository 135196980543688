import React, { Component } from 'react'
import { notEqual } from 'utils'
import { StyledDiv, StyledProgressBar, StyledButton } from './Controls.style'

let inactivityTimeout

class Controls extends Component {
  state = {
    xFocus: 1
  }

  componentDidMount() {
    this.resetControlFadeDelay()
  }

  hide = () => {
    const {
      player: { spooling, playing }
    } = this.props
    playing && !spooling && this.setState({ visible: false })
  }

  resetControlFadeDelay = () => {
    const { hide } = this
    clearTimeout(inactivityTimeout)
    inactivityTimeout = setTimeout(hide, 5000)
  }

  componentWillUnmount() {
    clearTimeout(inactivityTimeout)
  }

  componentDidUpdate(nextProps) {
    const { focus } = nextProps
    const {
      focus: {
        moveLeft,
        moveRight,
        pressBack,
        pressEnter,
        playPause,
        fastForward,
        rewind
      },
      player: { element: player, playing, progress, spooling }
    } = this.props
    const { xFocus } = this.state
    const { moved, pressedBack, pressedEnter } = this
    //if (!!player) {
    notEqual(moveLeft, focus.moveLeft) && xFocus > 0 && moved(xFocus - 1)
    notEqual(moveRight, focus.moveRight) && xFocus < 2 && moved(xFocus + 1)
    notEqual(pressBack, focus.pressBack) && pressedBack(player)
    notEqual(pressEnter, focus.pressEnter) &&
      pressedEnter(player, xFocus, progress, playing, spooling)
    notEqual(playPause, focus.playPause) &&
      pressedEnter(player, 1, progress, playing, spooling)
    notEqual(fastForward, focus.fastForward) &&
      pressedEnter(player, 2, progress, playing, spooling)
    notEqual(rewind, focus.rewind) &&
      pressedEnter(player, 0, progress, playing, spooling)
    //}
  }

  pressedBack = player => this.props.destroyPlayer(player)

  pressedEnter = (player, xFocus, progress, playing, spooling) => {
    const {
      rewind,
      playAndPause,
      fastForward,
      player: { actionType }
    } = this.props
    this.resetControlFadeDelay()
    this.setState({ xFocus, visible: true })
    if (xFocus === 0 && !spooling) {
      rewind(player, playing, progress)
    }
    if (xFocus === 1) {
      playAndPause(player, playing, progress, actionType)
    }
    if (xFocus === 2 && !spooling) {
      fastForward(player, playing, progress)
    }
  }

  moved = xFocus => {
    this.setState({ xFocus, visible: true })
    this.resetControlFadeDelay()
  }

  render() {
    const { xFocus, visible } = this.state
    const { REACT_APP_BUILD_TYPE } = process.env
    const {
      player: { progress, playing }
    } = this.props
    return (
      <StyledDiv style={{ opacity: visible ? 1 : 0 }}>
        <StyledProgressBar {...{ progress }} />
        <StyledButton focussed={xFocus === 0} icon={'rewind'} />
        <StyledButton
          focussed={xFocus === 1}
          icon={playing ? 'pause' : 'play'}
          build={REACT_APP_BUILD_TYPE}
        />
        <StyledButton focussed={xFocus === 2} icon={'ff'} />
      </StyledDiv>
    )
  }
}

export default Controls
