import styled from 'styled-components'

export const Debug = styled.div`
  font-family: Arial, serif;
  position: fixed;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 11px;
  line-height: 14px !important;
  width: 150px;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  > span {
    /* close button */
    display: block;
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 9px;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: #ccc;
    }
  }
  > p {
    color: #fff;
    margin: 0;
    font-size: 11px;
    line-height: 14px !important;
    > span {
      color: #f00;
    }
  }
`
