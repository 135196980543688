/**
 * @function checkEmail
 * Checks to see if an email is correctly formatted
 * @param {String} email
 */

export const checkEmail = email => (/\S+@\S+\.\S+/.test(email) ? true : false)

export const handleApiErrors = response =>
  response.status !== 200 ? Error(response.status) : response
