import { REQUEST_SHOW, RECEIVE_SHOW, ERROR_SHOW } from './actions'

const initialState = {
  episodes: [],
  episodesReady: false,
  errors: false
}

const showReducer = function showReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SHOW:
      return {
        episodes: [],
        episodesReady: false,
        errors: false
      }
    case RECEIVE_SHOW:
      return Object.assign({}, state, {
        episodes: action.payload,
        episodesReady: true
      })

    case ERROR_SHOW:
      return {
        episodes: [],
        episodesReady: false,
        errors: action.payload
      }
    default:
      return state
  }
}

export default showReducer
