import styled from 'styled-components'

export const StyledDiv = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
//
//
// export const StyledDebugDiv = styled.div`
//   position: fixed;
//   z-index: 1002;
//   top: 30px;
//   left: 30px;
//   padding: 10px;
//   background-color: rgba(0,0,0,0.6);
// `
