import styled from 'styled-components'
import play from 'img/play.svg'
import pause from 'img/pause.svg'
import stop from 'img/stop.svg'
import rewind from 'img/rewind.svg'
import ff from 'img/forward.svg'

export const StyledDiv = styled.div`
  background: rgba(255, 255, 255, 0.6);
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 3vh;
  padding-bottom: 3vh;
  border-radius: 0 0 2vw 2vw;
  margin-bottom: 4vh;
  transition: opacity 0.2s ease-out;
`

export const StyledProgressBar = styled.div`
  position: absolute;
  width: 100%;
  height: 1vh;
  background: #eee;
  top: 0;
  left: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1vh;
    background-color: #f00;
    width: ${({ progress }) => progress + '%'};
  }
`

export const StyledButton = styled.div`
  width: 9vh;
  height: 9vh;
  &:nth-of-type(3) {
    width: 12vh;
    height: 12vh;
    margin: 0 1vw;
  }
  border-radius: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #e72976;
  background-image: url(${({ icon, build }) =>
    icon === 'play'
      ? play
      : icon === 'pause'
      ? pause
      : icon === 'ff'
      ? ff
      : rewind});
  color: #fff;
  font-weight: bold;
  border: 4px solid ${({ focussed }) => (focussed ? '#FFF' : '#E72976')};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`
