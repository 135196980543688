/*
  HOC: Redux Provider and Redux-Connected Router
*/

import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

const ProviderRouter = ({ store, history, children }) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  )
}

export default ProviderRouter
