export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const ERROR_MODAL = 'ERROR_MODAL'

export function showModal(errors) {
  return dispatch => {
    dispatch(modal(errors))
  }
}

export function hideModal() {
  return dispatch => {
    dispatch(modalHide())
  }
}

function modal(payload) {
  return {
    type: SHOW_MODAL,
    payload
  }
}

function modalHide() {
  return {
    type: HIDE_MODAL
  }
}
