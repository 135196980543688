import React from 'react'
import { Route } from 'react-router-dom'
import Page from 'pages/Page'

const PageWithMenu = React.lazy(() => import('pages/PageWithMenu'))
const Logout = React.lazy(() => import('components/Logout'))

export const Routes = () => {
  return [
    { path: '/', component: Page },
    { path: '/logout', component: Logout },
    { path: '/sign-up/:step', component: PageWithMenu },
    { path: '/login', component: PageWithMenu },
    { path: '/home', component: PageWithMenu },
    { path: '/search', component: PageWithMenu },
    { path: '/show/:hid', component: PageWithMenu },
    { path: '/account', component: PageWithMenu },
    { path: '/help', component: PageWithMenu },
    { path: '/privacy', component: PageWithMenu },
    { path: '/terms', component: PageWithMenu },
    { path: '/404', component: PageWithMenu },
    { path: '/entitlement', component: PageWithMenu }
  ]
}

export const AppRoutes = route => (
  <Route
    exact
    path={route.path}
    render={props => <route.component {...props} />}
  />
)
