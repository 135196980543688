import React from 'react'
import ReactDOM from 'react-dom'
import ProviderRouter from './components/ProviderRouter'
import store, { history } from './store'
import App from './App'
import './style/index.css'
// import * as serviceWorker from './serviceWorker'

const render = () => {
  ReactDOM.render(
    <ProviderRouter store={store} history={history}>
      <App />
    </ProviderRouter>,
    document.getElementById('root')
  )
}

const preloader = document.getElementById('preloader')

setTimeout(() => {
  preloader.setAttribute('style', 'display: none')
  render()
}, 3000)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
