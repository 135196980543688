import styled from 'styled-components'
import bg from 'img/bg.svg'

export const breakpoints = {
  xxs: 437,
  xs: 572,
  sm: 767,
  md: 1023,
  lg: 1199,
  xl: 1399
}

export const Section = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(${bg});
  background-size: cover;
  h2 {
    margin-bottom: 4vh;
  }
  h3 {
    margin-bottom: 3vh;
  }
`

export const palette = {
  black: '#000',
  blackOpacity50: 'rgba(0,0,0,0.5)',
  white: '#FFF',
  orange: 'rgba(239,43,129,1)',
  errorRed: '#FFCCCC'
}
