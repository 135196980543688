import { getContent } from './getContent'

export const REQUEST_CONTENT = 'REQUEST_CONTENT'
export const RECEIVE_CONTENT = 'RECEIVE_CONTENT'

export const fetchContent = dispatch => dispatch => {
  dispatch(requestContent())
  const payload = getContent()
  dispatch(receiveContent(payload))
}

const requestContent = () => ({ type: REQUEST_CONTENT })
const receiveContent = payload => ({ type: RECEIVE_CONTENT, payload })
