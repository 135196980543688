export const LOGIN_USERNAME = 'LOGIN_USERNAME'
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const LOGIN_FROM_TOKEN_REQUEST = 'LOGIN_FROM_TOKEN_REQUEST'
export const LOGIN_WITH_AMAZON_REQUEST = 'LOGIN_WITH_AMAZON_REQUEST'
export const GET_LWA_TOKEN = 'GET_LWA_TOKEN'
export const SET_LWA_TOKEN = 'SET_LWA_TOKEN'
export const SUB_WITH_AMAZON_REQUEST = 'SUB_WITH_AMAZON_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_EXISTING = 'LOGIN_EXISTING'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

// Login

export const updateLoginForm = (value, type) => {
  const value_type = type === 'email' ? LOGIN_USERNAME : LOGIN_PASSWORD
  return {
    type: value_type,
    payload: value
  }
}

export const updateSignUpForm = (value, type) => {
  const value_type = type === 'email' ? LOGIN_USERNAME : LOGIN_PASSWORD
  return {
    type: value_type,
    payload: value
  }
}

/**
 * @function loginFromTokenRequest
 * Login from token request FLUX standard action creator
 */
export const loginFromTokenRequest = () => {
  return {
    type: LOGIN_FROM_TOKEN_REQUEST
  }
}

/**
 * @function loginWithAmazonRequest
 * Login from token request FLUX standard action creator
 */
export const loginWithAmazonRequest = res => {
  return {
    type: SET_LWA_TOKEN,
    payload: res.accessToken
  }
}

/**
 * @function signUpWithAmazonRequest
 * Login from token request FLUX standard action creator
 */
export const signUpWithAmazonRequest = res => {
  return {
    type: GET_LWA_TOKEN,
    payload: res.accessToken
  }
}

/**
 * @function loginRequest
 * Login request FLUX standard action creator
 */
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  }
}

/**
 * @function signUpRequest
 * Login request FLUX standard action creator
 */
export const signUpRequest = () => {
  return {
    type: SIGNUP_REQUEST
  }
}

export const updateUser = () => {
  return {
    type: UPDATE_USER
  }
}

/**
 * @function loginError
 * Login error FLUX standard action creator
 * @param {String} error
 */
export const loginError = payload => {
  return {
    type: LOGIN_ERROR,
    payload
  }
}

/**
 * @function loginSuccess
 */
export const loginSuccess = () => ({ type: LOGIN_SUCCESS })

// Logout

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  }
}

/**
 * @function logoutSuccess
 * Logout success FLUX standard action creator
 */
export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS
  }
}
